import React, { useState, MouseEventHandler, useEffect} from 'react';
import { addUserToMailList } from '../../utils/sendInBlue';
import Loading from '../base/Loading/Loading';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { setCookie } from '../../utils/helpers/cookie';
import { navigate } from 'gatsby';
import { verifyEmail } from '../../utils';
import { PULSE_SLIDE_UP_SESSION_ITEM, DEFAULT_SUBSCRIPTION_LIST } from '../../constants';

interface SignUpFormProps {
  isPopup: boolean | undefined;
  buttonLabel: string;
  hideFirstName?: boolean;
  formLocation: string;
  listId: number;
  redirectUrl?: string | undefined;
}

const SignUpForm: React.FC<SignUpFormProps> = ({
  isPopup = false,
  buttonLabel = 'Join Now',
  hideFirstName = false,
  formLocation = '',
  listId = DEFAULT_SUBSCRIPTION_LIST,
  redirectUrl = ''
}) => {
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (e) => {
    setLoading(true);
    setError('');
    e.preventDefault();
    if (executeRecaptcha) {
      if (verifyEmail(email)) {
        try {
          await executeRecaptcha('submit');
        } catch {
          setError('There was an error when processing your request. Please try again!');
        }
        const cleanListId = parseInt(listId.toString());
        const response = await addUserToMailList(firstName, email, formLocation, cleanListId);
        setLoading(false);
        if (response === 'success') {
          setEmail('');
          setFirstName('');
          if (isPopup && isClient) { // close the pop-up after submit the form.
            document.querySelector('.closePopup')?.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true }));
          }
          setCookie(PULSE_SLIDE_UP_SESSION_ITEM, '1', 365);
          const redirectPage = redirectUrl || '/thank-you/?pos=bottomFold'
          navigate(redirectPage);
        } else {
          setError('There was an error when processing your request. Please try again!');
        }
      } else {
        setLoading(false);
        setError('Invalid email format');
      }
    }
  };

  return (
    <form>
      {!hideFirstName && (
        <input
          type="text"
          placeholder="FIRST NAME"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      )}
      <input
        type="email"
        placeholder="EMAIL"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {error && <p className="error">{error}</p>}
      <button
        disabled={(!hideFirstName && firstName.length < 2) || !email || loading}
        onClick={handleSubmit}
        className="upBtn submit-form"
      >
        {loading ? <Loading /> : buttonLabel}
      </button>
    </form>
  );
};

export default SignUpForm;
