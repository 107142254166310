import React from 'react';
import { SVGProps } from 'react';

const BarsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={27.459} height={18} {...props}>
    <g fill="none" stroke="#003458" strokeLinecap="round" strokeWidth={2}>
      <path d="M1 1h25.459M1 8.274h25.459M1 15.548h25.459" />
    </g>
  </svg>
);

export default BarsIcon;
