import { IHeading } from '../../components/Article/components/TableOfContents/components/HeadingsContainer/HeadingsContainer.def';

export const getTableOfContentsHeadings = (articleContent: JSX.Element[]) => {
  const headings: IHeading[] = [];

  articleContent.forEach((articleContent) => {
    if (
      typeof articleContent !== 'string' &&
      typeof articleContent?.type === 'string' &&
      articleContent?.type === 'h2' &&
      articleContent.props.id
    ) {
      const id = articleContent?.props.id;
      let title;
      if (articleContent?.props?.children?.type) {
        // The code below removes html elements inside the heading element and get the string value
        title = articleContent.props.children.props?.children;
      }
      if (!title) {
        title = articleContent?.props.children;
      }
      headings.push({
        link: `#${id}`,
        title,
        subHeadings: [],
      });
    }
  });

  return headings;
};
