import React, { FC } from 'react';
import { Link } from 'gatsby';
import logo from '../../images/logo-UpgradedPoints.svg';
import Nav from '../Nav/Nav';
import SearchWrapper from '../base/SearchWrapper/SearchWrapper';
import { IHeader } from './Header.def';
import TimesIcon from '../../svgs/TimesIcon';
import BarsIcon from '../../svgs/BarsIcon';
import MenuCheckBox from '../base/MenuCheckBox/MenuCheckBox';

const Header: FC<IHeader> = ({
  headerLinks,
  advertiserDisclosure,
  showContentLinks = true,
  customHeaderClass = null,
}) => {
  const url = typeof window !== 'undefined' ? window.location.pathname : '';
  const searchButtonDisabled = /search/.test(url);
  const handleCloseDisclosure = () => {
    document.querySelector('.disclosure-modal-container')?.classList.add('open');
  };

  return (
    <header className={customHeaderClass || ''}>
      <div className="headerWrapper">
        {showContentLinks && (
          <>
            <MenuCheckBox className="primary-menu-toggle" />
            <button className="close-button">
              <TimesIcon className="headerIcon" />
            </button>
            <button className="open-button">
              <BarsIcon className="headerIcon" />
            </button>
          </>
        )}
        <Link to="/" className="headerLogo">
          <img src={logo} alt="Upgraded Points Logo" />
        </Link>

        {showContentLinks && <Nav headerLinks={headerLinks} />}
        {showContentLinks && (
          <div className="mobileNavToggle">
            <SearchWrapper isDisabled={searchButtonDisabled} />
          </div>
        )}

        <div className="advertiser-disclosure-container">
          <div className="advertiser-disclosure-wrap">
            <button className="advertiser-disclosure" onClick={handleCloseDisclosure}>
              {advertiserDisclosure.text}
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
