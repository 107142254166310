import React, { FC, useState } from 'react';
import { IQuickFacts } from './FullFeatured.def';
import AnimateHeight, { Height } from 'react-animate-height';
import ChevronDownIcon from '../../svgs/ChevronDownIcon';

const QuickFacts: FC<IQuickFacts> = ({ facts }) => {
  const [height, setHeight] = useState<Height>(210);
  const [extended, setExtend] = useState(false);

  const handleExtend = () => {
    setExtend(true);
    setHeight('auto');
  };
  return (
    <div className="quickFactsWrapper">
      <AnimateHeight height={height} className={`!extended ? 'isExtendable' : ''}`}>
        <div className="container quickFacts">
          {facts.map(({ label, children }, index) => (
            <div
              key={index}
              className={
                typeof label === 'string' ? label?.toLowerCase()?.replaceAll(' ', '-') : ''
              }
            >
              <label>{label}</label>
              <div>{children}</div>
            </div>
          ))}
        </div>
      </AnimateHeight>
      {!extended && (
        <div className="extendButtonWrapper">
          <div className="scroller-top-white-gradient"></div>
          <button onClick={handleExtend}>
            Show more <ChevronDownIcon fill="#FFF" />
          </button>
        </div>
      )}
    </div>
  );
};

export default QuickFacts;
