import React, { FC, useEffect, useState } from 'react';
import { IAdminBar } from './AdminBar.def';
import { gql } from '@apollo/client';
// @ts-ignore
import client from '../../gatsby-plugin-apollo/client';
import { isLocalStorageAllowed } from '../../utils/helpers/localStorage';

interface IViewer {
  firstName: string;
  avatar: {
    url: string;
  };
}

const GET_VIEWER = gql`
  query {
    viewer {
      firstName
      avatar {
        url
      }
    }
  }
`;

const AdminBar: FC<IAdminBar> = ({ wpUrl }) => {
  const [user, setUser] = useState(null as IViewer | null);
  const [path, setPath] = useState(null as string | null);

  useEffect(() => {
    setPath(window.location.pathname);

    let params = new URLSearchParams(window.location.search);
    let userToken = params.get('user_token');

    if (isLocalStorageAllowed()) {
      if (userToken) {
        window.localStorage.setItem('user_token', userToken);
      }

      userToken = window.localStorage.getItem('user_token');
    }

    if (userToken) {
      client
        .query({ query: GET_VIEWER })
        .then((result: any) => {
          if (result?.data?.viewer) {
            setUser(result.data.viewer);
          }
        })
        .catch((networkError: any) => {
          console.log(networkError);
          // If there's an issue and the localStorage is available, remove the user token.
          if (isLocalStorageAllowed()) {
            window.localStorage.removeItem('user_token');
          }
        });
    }
  });

  const renderUser = () => {
    if (!user) {
      return null;
    }

    return (
      <div className="upgp-user">
        Hello, {user.firstName} <img src={user.avatar.url} />
      </div>
    );
  };

  const renderEditLink = () => {
    if (!path || !wpUrl) {
      return null;
    }

    return (
      <div className="upgp-edit-link">
        <a href={wpUrl + path}>Edit Page Content</a>
      </div>
    );
  };

  if (!user || !path) {
    return null;
  }

  return (
    <div className="adminBar">
      {renderEditLink()}
      {renderUser()}
    </div>
  );
};

export default AdminBar;
