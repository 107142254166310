import React, { Fragment } from 'react';
import {
  DOMNode,
  domToReact,
  HTMLReactParserOptions,
  Element as ReactParserElement,
} from 'html-react-parser';

import {
  isElement,
  getStyleObjectFromString,
  getNeedToAddSourceFromCardLink,
  appendNoopenerSafely,
} from '..';
import { replaceDomainWithSiteDomain, replaceWpDomainWithFastlyDomain } from '.';
import Pulse from '../../components/Pulse';
import { renderImage } from './images';
import { renderIframe } from './iframe';
import TeamMembersContainer from '../../containers/TeamMembersContainer';
import CardTableESI from '../../components/CardTableESI/CardTableESI';
import CardArtESI from '../../components/CardArtESI/CardArtESI';
import LearnMoreESI from '../../components/LearnMoreESI/LearnMoreESI';
import ShortcodeESI from '../../components/ShortcodeESI/ShortcodeESI';
import CategoryNavContainer from '../../containers/CategoryNavContainer';
import AsSeenInContainer from '../../containers/AsSeenInContainer';
import HomeIntroContainer from '../../containers/HomeIntroContainer';
import LatestNewsContainer from '../../containers/LatestNewsContainer';
import TravelHubContainer from '../../containers/TravelHubContainer';
import HorizontalBrowserContainer from '../../containers/HorizontalBrowserContainer';
import SquareBrowserContainer from '../../containers/SquareBrowserContainer';
import TestimonialsContainer from '../../containers/TestimonialsContainer';
import HowWeWorkContainer from '../../containers/HowWeWorkContainer';
import AboutContentContainer from '../../containers/AboutContentContainer';
import PageHeaderContainer from '../../containers/PageHeaderContainer';
import TestimonialsOverviewContainer from '../../containers/TestimonialsOverviewContainer';
import ExploreTopContentContainer from '../../containers/ExploreTopContentContainer';
import CardImageShortcode from '../../components/CardImageShortcode';
import ApplyBtnShortcode from '../../components/ApplyBtnShortcode/ApplyBtnShortcode';
import UpShortcode from '../../components/UpShortcode';
import ScrollNotice from '../../components/base/ScrollNotice/ScrollNotice';
import SeeMore from '../../components/SeeMore';
import GoLink from '../../components/GoLink/GoLink';
import InlineTooltipWrapper from '../../wrappers/InlineTooltipWrapper';
import CapOneDisclaimerTooltip from '../../components/CapOneDisclaimerTooltip/CapOneDisclaimerTooltip';
import SignUpForm from '../../components/SignUpForm';
import getKeyTakeawaysOptions from '../static-queries/getKeyTakeawaysOptions';

import { FULL_WIDTH_SIDEBAR_MAX_WIDTH, MINIMUM_CELL_WIDTH } from '../../constants';
import { TTemplate } from '../../models/TTemplate';
import { ITableOptions } from '../../models/ITableOptions/ITableOptions';
import { computeTableMetrics } from './computeTableMetrics';
import { handleNonScrollableTable } from './handleNonScrollableTable';
import { handleScrollableTable } from './handleScrollableTable';
import { computeTableCellMetrics } from './computeTableCellMetrics';

export const componentMap: any = {
  CategoryNav: CategoryNavContainer,
  AsSeenIn: AsSeenInContainer,
  HomeIntro: HomeIntroContainer,
  LatestNews: LatestNewsContainer,
  TravelHub: TravelHubContainer,
  HorizontalBrowser: HorizontalBrowserContainer,
  SquareBrowser: SquareBrowserContainer,
  Testimonials: TestimonialsContainer,
  TestimonialsOverview: TestimonialsOverviewContainer,
  HowWeWork: HowWeWorkContainer,
  ExploreTopContent: ExploreTopContentContainer,
  AboutContent: AboutContentContainer,
  PageHeader: PageHeaderContainer,
  TeamMembers: TeamMembersContainer,
  CardImageShortcode: CardImageShortcode,
  ApplyBtnShortcode: ApplyBtnShortcode,
  UpShortcode: UpShortcode,
  SignUpBrevoForm: SignUpForm,
};

const checkIsComparisonTable = (domNode: DOMNode) => {
  if (isElement(domNode)) {
    return domNode.name === 'table' && domNode.attribs['class'] === 'upgp-comparison-table';
  }
  return false;
};
const checkIsTablePressTable = (domNode: DOMNode) => {
  if (isElement(domNode)) {
    return domNode.name === 'table' && domNode.attribs['class']?.includes('tablepress');
  }
  return false;
};

const getH2H3TextContent = (domNode: DOMNode): string | null => {
  if (isElement(domNode)) {
    for (const node of domNode.children) {
      if (node.type === 'text') {
        return node.data;
      }
      if (node.type === 'tag') {
        if (node.name === 'img') {
          return '';
        }
        const text: string | null = getH2H3TextContent(node);
        if (text) {
          return text;
        }
      }
    }
  }
  return null;
};

/**
 * Given an array of children nodes, check if the first child node is an image tag. If so, process the image.
 * If not, call domToReact on the children
 */
const processUpgradedPointsImagesInHyperlink = (domNodes: DOMNode[], isInternal = false) => {
  if (!domNodes || !domNodes.length) return null;
  const childTag = domNodes[0] as DOMNode;
  if (isElement(childTag)) {
    const imgTag = processUpgradedPointsImage(childTag, isInternal);
    if (imgTag) return imgTag;
  }

  return domToReact(domNodes);
};

/**
 * Check if this node is an image tag. If so, return the appropriate ReactNode.
 * If not, return false
 */
const processUpgradedPointsImage = (domNode: DOMNode, isInternal = false) => {
  if (isElement(domNode) && domNode.name === 'img' && domNode.attribs['data-src']) {
    if (!isInternal || (isInternal && domNode.attribs['data-src']?.includes('upgradedpoints'))) {
      return renderImage(domNode.attribs);
    }
  }
  return false;
};

/**
 * Check if this node is a hyperlink or Go Link. If so, return the appropriate ReactNode.
 * If not, return false
 */
export const processLinks = (domNode: DOMNode, placing: string, disableGoLinks = false) => {
  if (isElement(domNode) && domNode?.name === 'a' && !domNode.attribs['class']?.includes('aawp')) {
    const href = domNode?.attribs['href'];
    if (href) {
      const splitHref = href.split('/');
      // Note: We can't just check for a Go Link using href.includes('/go/').
      // This is because sometimes there are links such as "https://www.faa.gov/airports/resources/advisory_circulars/index.cfm/go/document.current/documentNumber/150_5360-14"
      // where the '/go/' is part of the url path and it is not considered a Go Link.
      if (splitHref[1] === 'go' || splitHref[3] === 'go') {
        const children = processChildrenWithPlacing(domNode.children, placing);
        if (disableGoLinks) {
          return <span>{children}</span>;
        }
        const needToAddSource = getNeedToAddSourceFromCardLink(href);
        return (
          <GoLink
            link={href}
            placingFromProps={placing}
            needToAddSource={needToAddSource}
            children={children}
            classNameFromProps={domNode?.attribs['class']}
          />
        );
      }
      if (href.includes('http')) {
        const { style: domNodeStyle, class: className, rel, ...rest } = domNode.attribs;
        const style = domNodeStyle ? getStyleObjectFromString(domNodeStyle) : {};
        //Process links to view internal images
        if (href?.includes('/wp-content/uploads/')) {
          return (
            <a
              className={className}
              style={style}
              target="_blank"
              {...rest}
              href={replaceWpDomainWithFastlyDomain(href || '')}
            >
              {processUpgradedPointsImagesInHyperlink(domNode.children, true)}
            </a>
          );
        }
        //Process external links
        if (!href.includes('upgradedpoints')) {
          return (
            <a
              className={className}
              style={style}
              target="_blank"
              rel={appendNoopenerSafely(rel)}
              {...rest}
            >
              {processUpgradedPointsImagesInHyperlink(domNode.children)}
            </a>
          );
        }
        //Process internal links. Make sure the proper protocol and domain are used
        return (
          <a
            className={className}
            style={style}
            rel={rel}
            {...rest}
            href={replaceDomainWithSiteDomain(href || '') || undefined}
          >
            {processUpgradedPointsImagesInHyperlink(domNode.children, true)}
          </a>
        );
      }
      // If See More summary link, return empty tag
      if (
        href === '#' &&
        domNode?.firstChild?.type === 'tag' &&
        isElement(domNode?.firstChild) &&
        domNode.firstChild.firstChild &&
        domNode.firstChild.firstChild['data'] === 'Summary'
      ) {
        return <></>;
      }
    }
  }
  return false;
};

/**
 * This function calls processLinks on a domNode subtee using a specific placing on the Go Links
 * in this subree
 */
const processLinksInChildrenWithPlacing = (domNodes: DOMNode[], placing: string) => {
  const options: HTMLReactParserOptions = {
    replace(domNode) {
      if (isElement(domNode)) {
        const linkNode = processLinks(domNode, placing);
        if (linkNode) return linkNode;
      }

      return domNode;
    },
  };
  return domToReact(domNodes, options);
};

/**
 * Process a domNode and it's children. This should be called within the replace function of the
 * options of a call to domToReact or parse from html-react-parser
 */
export const processDomNode = (
  domNode: DOMNode,
  placing: string,
  templateName?: TTemplate,
  tableOptions?: ITableOptions,
) => {
  if (isElement(domNode)) {
    const isComparisonTable = checkIsComparisonTable(domNode);
    const isTablePressTable = checkIsTablePressTable(domNode);
    //Process Links first
    const linkNode = processLinks(domNode, placing);
    if (linkNode) return linkNode;

    //Process the Pulse form
    if (domNode?.name === 'div' && domNode?.attribs['class']?.includes('upgp-pulse')) {
      return <Pulse isPopup={false} />;
    }

    const imgTag = processUpgradedPointsImage(domNode, true);
    if (imgTag) return imgTag;

    if (
      domNode.name === 'img' &&
      domNode.attribs['data-src'] &&
      !domNode.attribs['data-src']?.includes('upgradedpoints') &&
      !domNode.attribs['data-src']?.startsWith('/')
    ) {
      return renderImage(domNode.attribs, false);
    }

    if (domNode.name === 'iframe') {
      return renderIframe(domNode.attribs);
    }

    if (domNode?.name === 'h2' && domNode?.attribs['class']?.includes('upgp-kt-title')) {
      const ktTitle = getKeyTakeawaysOptions() || '';
      return <h2>{ktTitle}</h2>;
    }

    // Process the ESI components.
    if (domNode?.name === 'div' && domNode?.attribs['data-react-component'] === 'CardTableESI') {
      const { src } = JSON.parse(domNode?.attribs['data-react-props'] || '');
      return <CardTableESI src={src} />;
    }

    if (domNode?.name === 'div' && domNode?.attribs['data-react-component'] === 'CardArtESI') {
      const { src } = JSON.parse(domNode?.attribs['data-react-props'] || '');
      return <CardArtESI src={src} goLinkPlacing={placing} />;
    }

    if (domNode?.name === 'div' && domNode?.attribs['data-react-component'] === 'LearnMoreESI') {
      const { src } = JSON.parse(domNode?.attribs['data-react-props'] || '');
      return <LearnMoreESI src={src} goLinkPlacing={placing} />;
    }

    if (domNode?.name === 'span' && domNode?.attribs['data-react-component'] === 'ShortcodeESI') {
      const { src } = JSON.parse(domNode?.attribs['data-react-props'] || '');
      return <ShortcodeESI src={src} goLinkPlacing={placing} />;
    }

    if (domNode.attribs['data-react-component']) {
      let props;
      props = JSON.parse(domNode?.attribs['data-react-props'] || '');
      const componentName = domNode.attribs['data-react-component'];
      const Component = componentMap[componentName];
      return Component && <Component {...props} />;
    }

    // Code below adds id attribute to h2s elements with no id attribute so that they can be used in the table of contents
    if (domNode.name === 'h2' && domNode.children.length > 0) {
      // Code guarantees that already generated ids do not have leading number (credit-cards/wells-fargo-rewards-points-faqs/)
      if (domNode.attribs['id']) {
        const idValue = domNode.attribs['id'] || '';
        const firstIdChar = parseInt(idValue.charAt(0), 10);
        const isIdFirstCharNumber = !isNaN(firstIdChar);
        const { style: domNodeStyle, ...rest } = domNode.attribs;
        const hStyle = domNodeStyle ? getStyleObjectFromString(domNodeStyle) : {};
        if (isIdFirstCharNumber) {
          return (
            <h2 style={hStyle} {...rest} id={`h-${idValue}`.toLowerCase()}>
              {processLinksInChildrenWithPlacing(domNode.children, placing)}
            </h2>
          );
        }
      } else {
        const textContent = getH2H3TextContent(domNode) || '';
        const { style: domNodeStyle, ...rest } = domNode.attribs;
        const hStyle = domNodeStyle ? getStyleObjectFromString(domNodeStyle) : {};
        if (textContent) {
          return (
            <h2
              style={hStyle}
              {...rest}
              // Removes all the special characters: question mark, comma, full stops, etc.
              id={textContent
                .replaceAll(' ', '-')
                .replace(/[^a-zA-Z0-9 -]/g, '')
                .toLowerCase()}
            >
              {processLinksInChildrenWithPlacing(domNode.children, placing)}
            </h2>
          );
        }
      }
    }

    /*
      Code below adds adds heading-3 class to all h3 elements.
    */
    if (domNode.name === 'h3' && domNode.children.length > 0) {
      const textContent = getH2H3TextContent(domNode) || '';
      const { style: domNodeStyle, class: className, ...rest } = domNode.attribs;
      const hStyle = domNodeStyle ? getStyleObjectFromString(domNodeStyle) : {};
      if (textContent) {
        let newClass = `heading-3 ${className ? className : ''}`;
        if (
          domNode.next &&
          domNode.next.next &&
          isElement(domNode.next.next) &&
          domNode.next.next.name === 'h3'
        ) {
          newClass = `${newClass} heading-3-title`;
        }
        return (
          <h3 style={hStyle} {...rest} className={newClass}>
            {processLinksInChildrenWithPlacing(domNode.children, placing)}
          </h3>
        );
      }
    }

    if (isComparisonTable) {
      const { style: domNodeStyle, class: className, ...rest } = domNode.attribs;
      const tableStyle = domNodeStyle ? getStyleObjectFromString(domNodeStyle) : {};

      return (
        <Fragment>
          <ScrollNotice />
          <table className="comparison-table scrollable" style={tableStyle} {...rest}>
            {processChildrenWithPlacing(domNode.children, '/TBL/')}
          </table>
        </Fragment>
      );
    }

    if (isTablePressTable) {
      const { style: domNodeStyle, class: className, ...rest } = domNode.attribs;
      const tableStyle = domNodeStyle ? getStyleObjectFromString(domNodeStyle) : {};
      return (
        <Fragment>
          <ScrollNotice />
          <table style={tableStyle} {...rest} className={className}>
            {processChildrenWithPlacing(domNode.children, '/TT/')}
          </table>
        </Fragment>
      );
    }

    if (domNode.name === 'table') {
      const { style: domNodeStyle, class: className, ...rest } = domNode.attribs;
      const tableStyle = domNodeStyle ? getStyleObjectFromString(domNodeStyle) : {};
      let tableClassName = className;
      const isNewUpTable = tableClassName === 'upgp-table';
      let dimensions: ITableOptions | undefined;
      if (isNewUpTable) {
        const tbody = domNode.children[0] as ReactParserElement;
        const {
          tableWidth,
          firstColumnTableCellsAffectedByRowSpan,
          totalNumberOfCellsPerRow,
          numberOfCellsWithNoWidths,
        } = computeTableMetrics(tbody);
        if (templateName && tableWidth) {
          const tableContainer = domNode.parent as ReactParserElement;
          const isScrollable = tableContainer.attribs['class']?.includes('scrollable');
          if (!isScrollable) {
            dimensions = handleNonScrollableTable(templateName, tableWidth);
            if (dimensions) {
              dimensions = {
                ...dimensions,
                firstColumnTableCellsAffectedByRowSpan,
                numberOfCellsWithNoWidths,
              };
            }
          } else {
            tableClassName = handleScrollableTable(templateName, tableWidth, tableClassName || '');
          }
        }

        if (!dimensions) {
          dimensions = {
            originalTableWidth: tableWidth,
            targetTableWidth: FULL_WIDTH_SIDEBAR_MAX_WIDTH,
            firstColumnTableCellsAffectedByRowSpan,
            shouldUpdateWidth: numberOfCellsWithNoWidths > 0 ? true : false,
            numberOfCellsWithNoWidths,
          };
        }
        if (totalNumberOfCellsPerRow === 2) {
          tableClassName = `${tableClassName} mobile-no-scroll`;
          dimensions = {
            ...dimensions,
            tableHasTwoColumns: true,
          };
        }
      }
      return (
        <Fragment>
          {!isNewUpTable && <ScrollNotice />}
          <table style={tableStyle} {...rest} className={tableClassName}>
            {processChildrenWithPlacing(domNode.children, '/TE/', dimensions)}
          </table>
        </Fragment>
      );
    }

    if ((domNode.name === 'td' || domNode.name === 'th') && tableOptions) {
      const { style: cellStyleString, id, ...rest } = domNode.attribs;
      const { originalTableWidth, targetTableWidth, numberOfCellsWithNoWidths = 0 } = tableOptions;
      let workingOriginalTableWidth = originalTableWidth;
      const widthMatch = cellStyleString?.match(/width\s*:\s*(\d+(?:\.\d+)?)px/);
      const remainingWidth = targetTableWidth - workingOriginalTableWidth;
      let widthForCellWithNoWidth = MINIMUM_CELL_WIDTH;
      if (numberOfCellsWithNoWidths > 0) {
        const widthPerCell = remainingWidth / numberOfCellsWithNoWidths;
        if (widthPerCell > MINIMUM_CELL_WIDTH) {
          workingOriginalTableWidth = workingOriginalTableWidth + remainingWidth;
          widthForCellWithNoWidth = widthPerCell;
        } else {
          workingOriginalTableWidth =
            workingOriginalTableWidth * (numberOfCellsWithNoWidths * MINIMUM_CELL_WIDTH);
        }
      }
      let currentCellWidth;
      if (widthMatch && widthMatch[1]) {
        currentCellWidth = parseFloat(widthMatch[1]);
      } else {
        currentCellWidth = widthForCellWithNoWidth;
      }
      if (currentCellWidth) {
        const CellTag = domNode.name === 'th' ? 'th' : 'td';
        const { cellClassName, cellStyle } = computeTableCellMetrics({
          tableOptions,
          cellStyleString,
          currentCellWidth,
          id,
          isFirstColumnCell: !domNode.prev,
          parentElement: domNode.parent as ReactParserElement,
          widthForCellWithNoWidth,
        });
        return (
          <CellTag className={cellClassName} style={cellStyle} id={id} {...rest}>
            {processChildrenWithPlacing(domNode.children, '/TE/')}
          </CellTag>
        );
      }
    }

    if (domNode.attribs['class'] === 'upgp-see-more') {
      return (
        <div className="upgp-see-more">
          <SeeMore childNodes={processChildrenWithPlacing(domNode.children, placing)} />
        </div>
      );
    }

    if (domNode.name === 'span' && domNode.attribs['class'] === 'up-cap-one-disclaimer') {
      return (
        <InlineTooltipWrapper
          className="up-cap-one-disclaimer"
          linkChildren="*"
          TooltipComponentName={CapOneDisclaimerTooltip}
        />
      );
    }
  }
  return domNode;
};

/**
 * This function calls processDomNode on a domNode subtee using a specific placing on the Go Links
 * in this subree.
 */
const processChildrenWithPlacing = (
  domNodes: DOMNode[],
  placing: string,
  tableOptions?: ITableOptions,
) => {
  const options: HTMLReactParserOptions = {
    replace(domNode) {
      if (isElement(domNode)) {
        return processDomNode(domNode, placing, undefined, tableOptions);
      }

      return domNode;
    },
  };
  return domToReact(domNodes, options);
};
