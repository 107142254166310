import { ReactElement, useEffect } from 'react';
import { doTrackingForBI, updateSessionDataInGoLinks } from '../utils/helpers/goLinks';
import { useLocation } from '@reach/router';
import { getSourceMediumMappings } from '../utils/static-queries';

const TrackingWrapper = ({ children }: { children: ReactElement }) => {
  const location = useLocation();
  const path = location.pathname;
  const sourceMediumMappings = getSourceMediumMappings();

  useEffect(() => {
    doTrackingForBI(path, sourceMediumMappings);
    updateSessionDataInGoLinks();
  }, [path]);
  return children;
};

export default TrackingWrapper;
