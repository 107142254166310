import React, { FC } from 'react';
import { IMenuCheckBox } from './MenuCheckBox.def';

const MenuCheckBox: FC<IMenuCheckBox> = ({ className, ...rest }) => {
  return (
    <input
      {...rest}
      type="checkbox"
      aria-hidden="true"
      className={`mobile-menu-toggle ${className ? className : ''}`}
    />
  );
};

export default MenuCheckBox;
