import React, { useState, useEffect } from 'react';
import { HtEventsBrowser } from '@ht-sdks/events-sdk-js-browser';
import HTEventsContext from '../context/HTEventsContext';
import { HTEventsContextType } from '../context/HTEventsContext/HTEventsContext.def';
import { HT_SESSION_TIMEOUT } from '../constants';

interface ChildProps {
  children: JSX.Element;
}

const HTEventsProvider = ({ children }: ChildProps) => {
  const [htevents, setHtEvents] = useState<HTEventsContextType>(null);

  useEffect(() => {
    // TODO: proxy us-east-1.hightouch-events.com at /sr/
    const instance: HtEventsBrowser = HtEventsBrowser.load(
      { writeKey: process.env['HTEVENTS_WRITE_KEY'] || '' },
      {
        apiHost: 'us-east-1.hightouch-events.com',
        user: {
          sessions: {
            autoTrack: true,
            timeout: HT_SESSION_TIMEOUT, //The session timeout
          },
        },
      },
    );
    setHtEvents(instance);
  }, []);

  return <HTEventsContext.Provider value={htevents}>{children}</HTEventsContext.Provider>;
};

export default HTEventsProvider;
