import React from 'react';
import { SVGProps } from 'react';

const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
    <path fill="#2c70b8" d="M7.47 0h3v18h-3z" />
    <path fill="#2c70b8" d="M0 7.43h18v3H0z" />
  </svg>
);

export default PlusIcon;
