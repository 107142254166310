import { MouseEvent } from 'react';

/**
 * This function is the on click handler for menu link item
 * When JavaScript is enabled because we are doing client-side rendering, the dropdown menu doesn't
 * close on Desktop after the user clicks on a link and navigate to a new page
 */
export const handleNavLinkOnClick = (event: MouseEvent<Element, MouseEvent>) => {
  if (window.innerWidth >= 1024) {
    document.querySelectorAll('.dropdown')?.forEach((el) => {
      if (el.contains(event.currentTarget) || el.parentNode === event.currentTarget.parentNode)
        el.classList.add('hover-off');
    });
  } else {
    const checkboxes = document.querySelectorAll('.mobile-menu-toggle');
    if (checkboxes) {
      checkboxes.forEach((checkbox) => {
        if (checkbox.checked) {
          checkbox.checked = false;
        }
      });
    }
    if (document.body.classList.contains('body--overflow-hidden')) {
      document.body.classList.remove('body--overflow-hidden');
    }
  }
};
