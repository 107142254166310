import React, { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Header from '../components/Header';
import CardDataProvider from '../providers/CardDataProvider';
import Footer from '../components/Footer';
import { getMenuItems } from '../utils/static-queries';
import TrackingWrapper from '../wrappers/TrackingWrapper';
import DisclosureModal from '../components/DisclosureModal/DisclosureModal';
import GlobalPostProvider from '../providers/GlobalPostProvider';
import { isCreditCardsReviewPage, isPreview } from '../utils';
import AdminBar from '../components/AdminBar/AdminBar';
import ValuationModal from '../components/ValuationModal/ValuationModal';
import useIsMobile from '../hooks/useIsMobile';
import HelloBar from '../components/HelloBar';
import DOMEffectWrapper from '../wrappers/DOMEffectWrapper';
import { searchInToggleElements } from '../utils/helpers/toggleElements';
import { isLocalStorageAllowed } from '../utils/helpers/localStorage';
import PulsePopupContainer from '../containers/PulsePopupContainer';
import CCMProvider from '../providers/CCMProvider';

interface IPageContext {
  id: string;
  databaseId: number;
  templateName: string;
  toggleElements?: string[] | undefined;
}

interface ChildProps {
  children: JSX.Element;
  pageContext: IPageContext;
}

const Layout = ({ children, pageContext }: ChildProps) => {
  const isMobile = useIsMobile('phone');
  const isTabletOrMobile = useIsMobile();
  const { footerLinks, legalLinks, socialLinks, siteLine, mainMenuLinks, advertiserDisclosure } =
    getMenuItems();
  let isPageLander = false;

  if (pageContext) {
    isPageLander =
      pageContext.templateName === 'PaidLandingPage' ||
      pageContext.templateName === 'PaidLandingPageFullWidth';
  }

  useEffect(() => {
    if (isLocalStorageAllowed()) {
      const token = window.localStorage.getItem('token');
      const path = window.location.pathname;
      if (path !== '/preview/' && token) {
        window.localStorage.removeItem('token');
      }
    }
  }, []);

  let showHeader = true;
  let customHeaderClass = null;

  if (isPageLander) {
    showHeader = false;
    customHeaderClass = 'template-lander-header';
  }

  const { toggleElements } = pageContext || {};
  const isDisabledOptin = searchInToggleElements(
    { toggleElements },
    '_upgp_toggle_elements_disable_optin',
  );

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env['GATSBY_RECAPTCHA_SITE_KEY'] || ''}>
      <AdminBar wpUrl={process.env['GATSBY_WORDPRESS_URL']} />
      <HelloBar context={pageContext} />
      <Header
        headerLinks={mainMenuLinks}
        advertiserDisclosure={advertiserDisclosure}
        showContentLinks={showHeader}
        customHeaderClass={customHeaderClass}
      />
      <DisclosureModal />
      <main>
        <CCMProvider>
          <CardDataProvider>
            <TrackingWrapper>
              <GlobalPostProvider postDatabaseId={pageContext.databaseId}>
                <DOMEffectWrapper isMobile={isMobile} pageContext={pageContext}>
                  {children}
                </DOMEffectWrapper>
              </GlobalPostProvider>
            </TrackingWrapper>
          </CardDataProvider>
        </CCMProvider>
        <ValuationModal />
      </main>
      <Footer
        footerLinks={footerLinks}
        legalLinks={legalLinks}
        siteLine={siteLine}
        socialFooterLinks={socialLinks}
        pageId={pageContext?.databaseId}
      />
      {!isDisabledOptin &&
        !isTabletOrMobile &&
        !isPageLander &&
        typeof location !== 'undefined' &&
        !isCreditCardsReviewPage(location.pathname) &&
        !isPreview(location.pathname) && <PulsePopupContainer context={pageContext} />}
    </GoogleReCaptchaProvider>
  );
};

export default Layout;
