import React, { FC, useState } from 'react';
import { IPulse } from './Pulse.def';
import { Link } from 'gatsby';
import CloseModalIcon from '../../svgs/CloseModelIcon';
import { createSourceElements, createSrc, createSrcSet } from '../../utils';
import SignUpForm from '../SignUpForm';
import EmailOptin from '../EmailOptin';

import {
  LARGE_TAB_MIN_WIDTH_MQ,
  MOBILE_MAX_WIDTH_MQ,
  SMALL_TAB_MIN_WIDTH_MQ,
  DEFAULT_SUBSCRIPTION_LIST,
} from '../../constants';

const Pulse: FC<IPulse> = ({ isPopup, customOptin }) => {
  const [visible, setVisible] = useState(true);
  const imageSrc =
    '/wp-content/uploads/2018/09/Singapore-Airlines-First-Class-Suites-Frankfurt-to-Singapore-Middle-Seats-e1539189612538.jpg';
  const src = createSrc(imageSrc);
  const sourceElements = createSourceElements([
    {
      media: LARGE_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(imageSrc, [
        {
          options: {
            width: 600,
            height: 698,
          },
        },
      ]),
    },
    {
      media: SMALL_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(imageSrc, [
        {
          options: {
            width: 620,
            height: 248,
          },
        },
      ]),
    },
    {
      media: MOBILE_MAX_WIDTH_MQ,
      srcSet: createSrcSet(imageSrc, [
        {
          options: {
            width: 700,
          },
        },
      ]),
    },
  ]);

  return visible ? (
    <section className={`${isPopup ? 'pulsePopup upgp-email-optin' : 'upgp-email-optin'}`}>
      {isPopup && <CloseModalIcon className="closePopup" onClick={() => setVisible(!visible)} />}
      {customOptin ? (
        <EmailOptin isPopup={isPopup} emailOptin={customOptin} />
      ) : (
        <div className="pulseContainer">
          <div className="pulseContent">
            <p className="pulse-heading">
              INSIDERS ONLY: UP PULSE<sup>™</sup>

            </p>
            <div className="pulseThumbnail mobile-only">
              <figure>
                <picture>
                  {sourceElements}
                  <img src={src} alt="Deluxe Travel Provided by UP Pulse" loading="lazy" />
                </picture>
              </figure>
            </div>
            <div className="pulse-intro">
              <p>
                Get the&nbsp;
                <strong>latest travel tips, crucial news, flight & hotel deal alerts...</strong>
              </p>
              <p>
                Plus — expert strategies to <strong>maximize your points & miles</strong> by joining
                our (free) newsletter.
              </p>
            </div>
            <SignUpForm
              isPopup={isPopup}
              buttonLabel="Join Now"
              formLocation={isPopup ? `UP PULSE Popup Form` : `UP PULSE Static Form`}
              listId={DEFAULT_SUBSCRIPTION_LIST}
            />
            <div className="termsAndPrivacy">
              <p>
                We respect your <Link to="/privacy-policy/">privacy</Link>. This site is protected
                by reCAPTCHA. Google's&nbsp;
                <a href="https://policies.google.com/privacy" target="_blank" rel="noopener">
                  privacy policy
                </a>
                &nbsp; and&nbsp;
                <a href="https://policies.google.com/terms" target="_blank" rel="noopener">
                  terms of service
                </a>
                &nbsp; apply.
              </p>
            </div>
          </div>
          <div className="pulseThumbnail">
            <figure>
              <picture>
                {sourceElements}
                <img src={src} alt="Deluxe Travel Provided by UP Pulse" loading="lazy" />
              </picture>
            </figure>
          </div>
        </div>
      )}
    </section>
  ) : (
    <></>
  );
};

export default Pulse;
