import { DEFAULT_SUBSCRIPTION_LIST } from '../../constants';
import { getSessionId } from '../helpers/goLinks';
export const addUserToMailList = async (
  firstName: string,
  email: string,
  formSource: string,
  listId: number,
) => {
  const contactList = listId || DEFAULT_SUBSCRIPTION_LIST;
  const listIds = [contactList];
  const requestBody = {
    email,
    updateEnabled: true,
    attributes: {
      FIRSTNAME: firstName,
      FORM_SOURCE: formSource,
      SESSION_ID: getSessionId(),
    },
    listIds,
  };
  try {
    const response = await fetch('https://merely-present-falcon.edgecompute.app/subscribe/', {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.status >= 200 && response.status <= 299 ? 'success' : 'failed';
  } catch {
    return 'failed';
  }
};
