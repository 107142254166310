import React, { FC } from 'react';

import PlusIcon from '../../svgs/PlusIcon';
import MinusIcon from '../../svgs/MinusIcon';

interface ISeeMoreProps {
  childNodes: string | JSX.Element | JSX.Element[];
}

const SeeMore: FC<ISeeMoreProps> = ({ childNodes }) => {
  return (
    <>
      <input type="checkbox" className="see-more-toggle-button" aria-hidden="true" />
      <div className="summary-toggle">
        <MinusIcon className="hide-summary" />
        <span className="hide-summary">Hide Summary</span>
        <PlusIcon className="show-summary" />
        <span className="show-summary">Show Summary</span>
      </div>
      <div className="article-summary">{childNodes}</div>
    </>
  );
};

export default SeeMore;
