import React, { FC } from 'react';
import SubNav from '../SubNav/SubNav';
import { INav } from './Nav.def';
import { Link } from 'gatsby';
import MenuCheckBox from '../base/MenuCheckBox/MenuCheckBox';
import { handleNavLinkOnClick } from '../../utils/helpers/handleNavLinkOnClick';

const Nav: FC<INav> = ({ headerLinks }) => {
  const renderNav = () => {
    return (
      <>
        {headerLinks?.length &&
          headerLinks.map(({ link, text, subMenu }, index) => (
            <li key={index}>
              <Link
                onClick={handleNavLinkOnClick}
                to={link || '#'}
                onMouseOver={(e) => {
                  const dropdownEl = e.currentTarget.parentElement?.querySelector('.hover-off');
                  if (dropdownEl) {
                    dropdownEl.classList.remove('hover-off');
                  }
                }}
                className={`${subMenu?.categories ? 'desktop-dropdown-menu' : ''}`}
              >
                {text}
              </Link>
              {subMenu?.categories && (
                <>
                  <MenuCheckBox className="sub-menu-item" />
                </>
              )}
              {subMenu?.categories && (
                <div className="dropdown" key={index}>
                  {subMenu?.categories?.map((category, index) => (
                    <SubNav className="dropdownCategory" category={category} key={index} />
                  ))}
                </div>
              )}
            </li>
          ))}
      </>
    );
  };
  return (
    <nav className="headerNav">
      <ul>{renderNav()}</ul>
    </nav>
  );
};

export default Nav;
