import React from 'react';
import { SVGProps } from 'react';

const ChevronUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15.524} height={8.618} {...props}>
    <path
      d="m7.338.181-7.056 6.8a1.087 1.087 0 0 0-.141 1.27.609.609 0 0 0 .988.181l6.633-6.4 6.633 6.4a.609.609 0 0 0 .988-.181 1.083 1.083 0 0 0 .141-.544 1.014 1.014 0 0 0-.282-.726L8.185.181a.585.585 0 0 0-.847 0Z"
      fill={`${props.fill ? props.fill : '#0f75bd'}`}
    />
  </svg>
);

export default ChevronUpIcon;
