import React from 'react';
import { SVGProps } from 'react';

const MinusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 3" {...props}>
    <path fill="#2c70b8" d="M0 0h18v3H0z" />
  </svg>
);

export default MinusIcon;
