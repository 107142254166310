import React, { FC, useEffect, useContext, useState, useRef } from 'react';
import parse from 'html-react-parser';
import CardDataContext from '../context/CardDataContext';
import { getExistingHtml } from '../utils';
import { getUpdateSessionDataInGoLinksOptions } from '../utils/helpers/goLinks';
import { DUMMY_GO_LINK_PLACING } from '../constants';

interface IHTMLContentESIWrapper {
  cardId: number;
  containerId: string;
  src: string;
  fallbackRenderFunction: any;
  extraParams: any[];
  goLinkPlacing?: string | undefined;
  tagName?: 'div' | 'span' | 'section';
  isCardTable?: boolean | undefined;
}

const HTMLContentESIWrapper: FC<IHTMLContentESIWrapper> = ({
  cardId,
  containerId,
  src,
  fallbackRenderFunction,
  extraParams,
  goLinkPlacing = null,
  tagName = 'div',
  isCardTable = false,
}) => {
  const [content, setContent] = useState(parse(`<esi:include src="${src}"></esi:include>`));
  const { cardDataJson } = useContext(CardDataContext);
  const allCardData = cardDataJson?.data;
  const TagName = tagName;

  if (typeof window === 'undefined') {
    return <TagName id={containerId}>{content}</TagName>;
  }

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef?.current?.innerHTML.includes('esi:include')) {
      let fallbackRenderFunctionParams = [];
      if (allCardData) {
        const card = allCardData && cardId && allCardData.find((c) => c.cardId === cardId);

        // Support the sending of all the card data to the fallback render function if the specific
        // card is not set. This is for components that require all the cards data like the disclosures.
        if (card) {
          fallbackRenderFunctionParams = [card, ...extraParams];
        } else {
          fallbackRenderFunctionParams = [allCardData, ...extraParams];
        }

        let contentString = fallbackRenderFunction(...fallbackRenderFunctionParams);
        if (goLinkPlacing) {
          // In case this shortcode has a Go Link with a dummy placing, replace it
          contentString = contentString?.replaceAll(DUMMY_GO_LINK_PLACING, goLinkPlacing);
        }
        setContent(<>{parse(contentString || '', getUpdateSessionDataInGoLinksOptions())}</>);
      }
    }
  }, [allCardData, containerRef]);

  useEffect(() => {
    if (isCardTable) {
      const handleBonusValuationClick = () => {
        const valuationModal = window.document.getElementsByClassName('valuation-modal-container');
        if (valuationModal[0]) {
          (valuationModal[0] as HTMLElement).style.display = '';
        }
      };
      const bonusValuation: any = containerRef.current?.querySelector('.highlight.bonus-valuation');
      if (bonusValuation && !bonusValuation?.clickEventSet) {
        bonusValuation.addEventListener('click', handleBonusValuationClick);
        bonusValuation.clickEventSet = true;
      }

      return () => {
        // Cleanup function to remove the event listener
        if (bonusValuation) {
          bonusValuation.removeEventListener('click', handleBonusValuationClick);
        }
      };
    }

    return;
  }, [content, containerRef, isCardTable]);

  //If this is the first time trying to mount the component client-side, check if we need to prevent flickering due to ESI
  let existingHtml = getExistingHtml(`#${containerId}`, 'innerHTML');
  if (existingHtml && !existingHtml?.includes('esi:include')) {
    if (goLinkPlacing) {
      //In case this shortcode has a Go Link with a dummy placing, replace it
      existingHtml = existingHtml?.replaceAll(DUMMY_GO_LINK_PLACING, goLinkPlacing);
    }
    return (
      <TagName ref={containerRef} id={containerId}>
        {parse(existingHtml)}
      </TagName>
    );
  }

  return (
    <TagName ref={containerRef} id={containerId}>
      {content}
    </TagName>
  );
};

export default HTMLContentESIWrapper;
