import React, { FC } from 'react';
import dunkinLogo from '../../../../images/dunkin_logo.svg';

const LTO: FC = () => {
  return (
    <div className="lto-container">
      <span className="lto-pill">NEW</span>
      <div className="lto-offers">
        <div className="lto-resy">
          <span>$100 Resy Credit</span>
        </div>
        <div className="lto-dunkin">
          <img src={dunkinLogo} alt="Upgraded Points Logo" />
          <span>$84 Dunkin’ Credit</span>
        </div>
      </div>
    </div>
  );
};

export default LTO;
