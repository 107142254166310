import React from 'react';
import { SVGProps } from 'react';

const ChevronDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15.524} height={8.618} {...props}>
    <g transform="translate(15.524 -145.454) rotate(90)">
      <path
        d="M153.891,7.338l-6.8-7.056a1.087,1.087,0,0,0-1.27-.141.609.609,0,0,0-.181.988l6.4,6.633-6.4,6.633a.609.609,0,0,0,.181.988,1.083,1.083,0,0,0,.544.141,1.014,1.014,0,0,0,.726-.282l6.8-7.057A.585.585,0,0,0,153.891,7.338Z"
        transform="translate(0 0)"
        fill={`${props.fill ? props.fill : '#697a87'}`}
      />
    </g>
  </svg>
);

export default ChevronDownIcon;
