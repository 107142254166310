import { ReactElement, useContext, useEffect, useState } from 'react';
import { doTrackingForBI, updateSessionDataInGoLinks } from '../utils/helpers/goLinks';
import { useLocation } from '@reach/router';
import { getSourceMediumMappings } from '../utils/static-queries';
import HTEventsContext from '../context/HTEventsContext';
import { sendHtIdentifyEvent } from '../utils/helpers/htApi';

const TrackingWrapper = ({ children }: { children: ReactElement }) => {
  const location = useLocation();
  const path = location.pathname;
  const sourceMediumMappings = getSourceMediumMappings();
  const [newUserCreated, setNewUserCreated] = useState(false);
  const htevents = useContext(HTEventsContext);

  useEffect(() => {
    const newUser = doTrackingForBI(path, sourceMediumMappings);
    if (newUser) {
      setNewUserCreated(true);
    }
    updateSessionDataInGoLinks();
  }, [path]);

  useEffect(() => {
    if (newUserCreated) {
      sendHtIdentifyEvent(htevents);
    }
  }, [htevents, newUserCreated]);

  return children;
};

export default TrackingWrapper;
