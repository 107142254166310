import React from 'react';
import { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import useClickOutside from '../../hooks/useClickOutside';

const ValuationModal = () => {
  const ref = useRef<HTMLInputElement>(null);

  const handleClose = () => {
    const parent = ref?.current?.parentElement;
    if (parent) {
      parent.style.display = 'none';
    }
  };

  // handle what happens on click outside of modal
  useClickOutside(ref, handleClose);

  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) => (e.key === 'Escape' ? handleClose() : null);
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  });

  return (
    <div className="valuation-modal-container" style={{ display: 'none' }}>
      <div className="valuation-modal-content" ref={ref}>
        <div className="valuation-button-container">
          <button className="valuation-button" onClick={handleClose}>
            <p>&times;</p>
          </button>
        </div>
        <div className="valuation-text">
          <p>
            <strong>UP's Bonus Valuation</strong>
          </p>
          <hr />
          <p>
            This bonus value is an estimated valuation&nbsp;
            <Link to={`/travel/points-and-miles-valuations/`}>calculated by UP</Link> after
            analyzing redemption options, transfer partners, award availability and how much UP
            would pay to buy these points.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ValuationModal;
