import React, { FC, useState, useEffect } from 'react';
import { getAllHelloBars } from '../../utils/static-queries/';
import { IHelloBar, HelloBars, HelloBarInterface, Context } from './HelloBar.def';
import IconTrophy from '../../svgs/IconTrophy';
import { useLocation } from '@reach/router';
import { cleanString } from '../../utils/helpers/cleanString';
import { findMatchingTopSlider } from '../TopSlider/TopSlider';
import { getAllTopSliders } from '../../utils/static-queries';
import { TopSliderObject } from '../TopSlider/TopSlider.def';

/**
 * Finds an appropriate hello bar based on the given context.
 * @param hellobars An object containing hello bars or null if no hello bars are available.
 * @param context The current context to determine which hello bar should be displayed.
 * @returns The matching HelloBarInterface object or null if no match is found.
 */
const findMatchingHelloBar = (
  hellobars: HelloBars | null,
  context: Context
): HelloBarInterface | null => {
  // Check if there are any hello bars available
  if (!hellobars?.upgpAllHelloBars) return null;

  for (const helloBar of hellobars.upgpAllHelloBars) {
    // Skip if the hello bar should not be displayed
    if (!helloBar.displayHelloBar) continue;

    let isHidden = helloBar.hideOn?.some((hideCondition) => {
      const hidesPost = hideCondition.hidePosts?.some(
        (post) => post?.databaseId === context?.databaseId
      );
      const hidesTag = hideCondition.hideTags?.some((tag) =>
        context?.tags?.nodes.some((node) => node.slug === tag.slug)
      );
      const hidesCategory = hideCondition.hideCategories?.some((category) =>
        context?.categories?.nodes.some((node) => node.slug === category.slug)
      );
      return hidesPost || hidesTag || hidesCategory;
    });

    // Skip if the hello bar is hidden based on conditions
    if (isHidden) continue;

    // Check display conditions
    if (helloBar.displayOn) {
      for (const displayCondition of helloBar.displayOn) {
        const matchesCondition =
          displayCondition?.displayPosts?.some(
            (post) => post?.databaseId === context?.databaseId
          ) ||
          displayCondition?.displayPages?.some(
            (page) => page?.databaseId === context?.databaseId
          ) ||
          displayCondition?.displayCategories?.some((category) =>
            context?.categories?.nodes.some((node) => node.slug === category.slug)
          ) ||
          displayCondition?.displayPagesUrl === context?.link ||
          displayCondition?.displayTags?.some((tag) =>
            context?.tags?.nodes.some((node) => node.slug === tag.slug)
          );

        // Return the hello bar if it matches the display conditions
        if (matchesCondition) return helloBar;
      }
    }
  }

  // Return null if no matching hello bar is found
  return null;
};

function setCookie(name: string, value: string, days: number) {
  let expires = '';
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

function getCookie(name: string) {
  let nameEQ = name + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c?.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c?.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const HelloBar: FC<IHelloBar> = ({ context }) => {

  // Retrieve all hello bars & top sliders.
  const { helloBar } = getAllHelloBars();
  const { creditCardReviewTopSlider } = getAllTopSliders();

  // Attempt to find a hello bar and top slider that match the current context.
  const matchingHelloBar = findMatchingHelloBar( helloBar as HelloBars, context);
  const isTopSliderReview = findMatchingTopSlider( creditCardReviewTopSlider as TopSliderObject, context );

  const cookieTitle = matchingHelloBar?.title || '';
  const cookieName = 'helloBarCookie-' + cleanString(cookieTitle);
  const themeClass = matchingHelloBar?.hellobarStyle || '';

  const [isVisible, setIsVisible] = useState(true);
  const location = useLocation();

  //check the value when loading page
  useEffect(() => {
    const checkCookie = () => {
      const initialVisible = getCookie(cookieName) === 'false';
      setIsVisible(initialVisible);
    };
    checkCookie();
    return () => {};
  }, [location.pathname]);

  const handleCloseClick = () => {
    setIsVisible(true);
    setCookie(cookieName, 'false', 7); // store 7 days
  };

  // Conditional rendering based on whether a matching hello bar is found and if the top slider is not meant to be displayed in the same context.
  // If either no matching hello bar is found or the top slider is displayed, the component renders nothing.
  if ( ! matchingHelloBar || isTopSliderReview ) return null;

  return (
    <div className={`up-hello-bar ${!isVisible ? 'visible' : 'hidden'} ` + themeClass }>
      <div className="icon-bar">
        <IconTrophy fill="#fff" />
      </div>
      <div className="title-bar">
        <span>{matchingHelloBar.title}</span>
      </div>
      {matchingHelloBar.buttonLabel && matchingHelloBar.buttonUrl && (
        <a className="learn-more-btn" href={matchingHelloBar.buttonUrl}>
          {matchingHelloBar.buttonLabel}
        </a>
      )}
      <a className="close-hello-bar" onClick={handleCloseClick}>
        +<span>No Thanks</span>
      </a>
    </div>
  );
};

export default HelloBar;
