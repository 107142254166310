import React, { FC } from 'react';
import SubNav from '../SubNav/SubNav';
import ChevronDownIcon from '../../svgs/ChevronDownIcon';
import { INav } from './Nav.def';
import ChevronUpIcon from '../../svgs/ChevronUpIcon';
import { Link } from 'gatsby';
import MenuCheckBox from '../base/MenuCheckBox/MenuCheckBox';
import { handleNavLinkOnClick } from '../../utils/helpers/handleNavLinkOnClick';

const Nav: FC<INav> = ({ headerLinks }) => {
  const renderMainNavLink = (text?: string) => (
    <>
      {text}
      <button>
        <ChevronDownIcon className="desktop-dropdown-icon" />
      </button>
    </>
  );

  const renderNav = () => {
    return (
      <>
        {headerLinks?.length &&
          headerLinks.map(({ link, text, subMenu }, index) => (
            <li key={index}>
              {subMenu?.categories ? (
                <Link
                  onClick={handleNavLinkOnClick}
                  onMouseOver={(e) => {
                    const dropdownEl = e.currentTarget.parentElement?.querySelector('.hover-off');
                    if (dropdownEl) {
                      dropdownEl.classList.remove('hover-off');
                    }
                  }}
                  to={link || '#'}
                >
                  {renderMainNavLink(text)}
                </Link>
              ) : (
                <Link onClick={handleNavLinkOnClick} to={link || '#'}>
                  {text}
                </Link>
              )}
              {subMenu?.categories && (
                <>
                  <MenuCheckBox className="sub-menu-item" />
                  <button className="mobileNav close-button">
                    <ChevronUpIcon fill="#929EA7" />
                  </button>
                  <button className="mobileNav open-button">
                    <ChevronDownIcon fill="#929EA7" />
                  </button>
                </>
              )}
              {subMenu?.categories && (
                <div className="dropdown" key={index}>
                  <div className="dropdownContainer">
                    <div className="mainCategoryContainer">
                      {subMenu?.categories?.map((category, index) => (
                        <SubNav className="dropdownCategory" category={category} key={index} />
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div className="selectedNavContainer">
                <div className="fakeDiv"></div>
                <div className="selectedNavItem"></div>
              </div>
            </li>
          ))}
      </>
    );
  };
  return (
    <nav className="headerNav">
      <ul>{renderNav()}</ul>
    </nav>
  );
};

export default Nav;
