import React, { FC } from 'react';
import ChevronDownIcon from '../../svgs/ChevronDownIcon';
import ChevronUpIcon from '../../svgs/ChevronUpIcon';
import { Link } from 'gatsby';
import { ISubNav } from './SubNav.def';
import { INavCategory } from '../../models/INavCategory';
import parser from 'html-react-parser';
import MenuCheckBox from '../base/MenuCheckBox/MenuCheckBox';
import { handleNavLinkOnClick } from '../../utils/helpers/handleNavLinkOnClick';

const SubNav: FC<ISubNav> = ({ className, category }) => {
  const lowercaseVs = (str: string): string => {
    const replacement = '<span class="versus">vs.</span>';
    return str.replace('vs.', replacement);
  };

  const renderLinks = (category: INavCategory) => {
    const { links, allLink, layout } = category;
    let ulProps: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLUListElement>,
      HTMLUListElement
    > = {};

    if (layout === 'sideBySide') {
      ulProps = {
        ...ulProps,
        className: 'sideBySide',
      };
    }
    return (
      <>
        <ul className="mobile-sub-menu-item__menu" {...ulProps}>
          {links.map(({ text, link }, index) => (
            <li key={index}>
              <img src="/shared/nav-arrow.svg" width={11} height={16} loading="lazy" alt="Submenu arrow icon" />
              <Link onClick={handleNavLinkOnClick} to={link}>
                {parser(lowercaseVs(text))}
              </Link>
            </li>
          ))}
        </ul>
        {allLink && (
          <Link onClick={handleNavLinkOnClick} className="allLink" to={allLink.link}>
            {allLink.text}
          </Link>
        )}
      </>
    );
  };

  const { title } = category;

  return (
    <>
      <div className={`dropdown-subnav ${className ? className : ''}`}>
        <p className="submenu-title">{title.text}</p>
        <MenuCheckBox className="sub-menu-item" />
        <button className="mobileNav close-button">
          <ChevronUpIcon fill="#000" />
        </button>
        <button className="mobileNav open-button">
          <ChevronDownIcon fill="#000" />
        </button>
        {renderLinks(category)}
      </div>
    </>
  );
};

export default SubNav;
