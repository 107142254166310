import { HTEventsContextType } from '../../context/HTEventsContext/HTEventsContext.def';
import { WindowLocation } from '@reach/router';
import { getSessionNumberFromLS, getUserIdFromLS } from './goLinks';

/**
 * Send a Hightouch Page event
 * @param htevents - the global handle
 * @param category - the category list for the page, in the format of a comma separated list of strings
 * @param title - the title of the page
 * @param location - location data for the page, from the react page context
 * @param referrer - last page visited
 */
export const sendHtPageView = (
  htevents: HTEventsContextType,
  category: string,
  title: string,
  location: WindowLocation,
  referrer: string,
) => {
  if (htevents) {
    const biSessionNumber = getSessionNumberFromLS();
    htevents.page(category, title, {
      path: location?.pathname || '',
      url: location?.href || '',
      referrer,
      biSessionNumber,
    });
  }
};

export const sendHtIdentifyEvent = (htevents: HTEventsContextType) => {
  if (htevents) {
    const biUserId = getUserIdFromLS();
    if (biUserId) {
      htevents.identify(biUserId);
    }
  }
};
